<script setup lang="ts">
useHead({
	link: [
		{ rel: 'apple-touch-icon', sizes: '57x57', href: '/apple-icon-57x57.png' },
		{ rel: 'apple-touch-icon', sizes: '60x60', href: '/apple-icon-60x60.png' },
		{ rel: 'apple-touch-icon', sizes: '72x72', href: '/apple-icon-72x72.png' },
		{ rel: 'apple-touch-icon', sizes: '76x76', href: '/apple-icon-76x76.png' },
		{ rel: 'apple-touch-icon', sizes: '114x114', href: '/apple-icon-114x114.png' },
		{ rel: 'apple-touch-icon', sizes: '120x120', href: '/apple-icon-120x120.png' },
		{ rel: 'apple-touch-icon', sizes: '144x144', href: '/apple-icon-144x144.png' },
		{ rel: 'apple-touch-icon', sizes: '152x152', href: '/apple-icon-152x152.png' },
		{ rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-icon-180x180.png' },
		{ rel: 'icon', type: 'image/png', sizes: '192x192', href: '/android-icon-192x192.png' },
		{ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
		{ rel: 'icon', type: 'image/png', sizes: '96x96', href: '/favicon-96x96.png' },
		{ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
		{ rel: 'manifest', href: '/manifest.json' }
	],
	meta: [
		{ name: 'msapplication-TileColor', content: '#ffffff' },
		{ name: 'msapplication-TileImage', content: '/ms-icon-144x144.png' },
		{ name: 'theme-color', content: '#ffffff' }
	],
	script: [
		{
			tagPosition: 'head',
			/*to prevent Firefox FOUC, this must be here*/
			innerHTML: `let FF_FOUC_FIX;`
		},
		{
			tagPosition: 'bodyOpen',
			innerHTML: '0',
		}
	]
});
</script>

<template>
	<div class="max-w-[100dvw] w-dvw">
		<NuxtRouteAnnouncer />
		<NuxtLoadingIndicator />
		<NuxtLayout>
			<NuxtPage/>
		</NuxtLayout>
	</div>
</template>

<style>
.page-enter-active,
.page-leave-active {
	transition: all 0.2s;
}

.page-enter-from,
.page-leave-to {
	filter: grayscale(1);
}
</style>
