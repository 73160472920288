import { default as about_45usvEJEw9lXujMeta } from "/home/clockwork/Projects/Web/evonap-site/pages/about-us.vue?macro=true";
import { default as _91slug_93VZO4iEW5bIMeta } from "/home/clockwork/Projects/Web/evonap-site/pages/blogs/[slug].vue?macro=true";
import { default as indexrtZkZDYel4Meta } from "/home/clockwork/Projects/Web/evonap-site/pages/blogs/index.vue?macro=true";
import { default as index8VBa2pE0MLMeta } from "/home/clockwork/Projects/Web/evonap-site/pages/index.vue?macro=true";
import { default as join_45usGsTIwInt65Meta } from "/home/clockwork/Projects/Web/evonap-site/pages/join-us.vue?macro=true";
import { default as _91slug_93DeavOScagmMeta } from "/home/clockwork/Projects/Web/evonap-site/pages/product/[slug].vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    meta: about_45usvEJEw9lXujMeta || {},
    component: () => import("/home/clockwork/Projects/Web/evonap-site/pages/about-us.vue")
  },
  {
    name: "blogs-slug",
    path: "/blogs/:slug()",
    component: () => import("/home/clockwork/Projects/Web/evonap-site/pages/blogs/[slug].vue")
  },
  {
    name: "blogs",
    path: "/blogs",
    component: () => import("/home/clockwork/Projects/Web/evonap-site/pages/blogs/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/clockwork/Projects/Web/evonap-site/pages/index.vue")
  },
  {
    name: "join-us",
    path: "/join-us",
    component: () => import("/home/clockwork/Projects/Web/evonap-site/pages/join-us.vue")
  },
  {
    name: "product-slug",
    path: "/product/:slug()",
    component: () => import("/home/clockwork/Projects/Web/evonap-site/pages/product/[slug].vue")
  }
]