import type { RouterOptions } from '@nuxt/schema';
export default <RouterOptions> {
    scrollBehavior(to, from, savedPosition) {
        if (to.path.includes('/product/standard') ||
            to.path.includes('/product/deluxe') ||
            to.path.includes('/product/compact') ||
            to.path.includes('/product/custom'))
        {
            return false;
        }
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                top: 0,
                behavior: 'smooth',
            }
        }
    }
}
